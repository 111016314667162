
import React, {
  useEffect, useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope, faUnlockAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, FormControl, Modal
} from '@themesberg/react-bootstrap';
import {
  Link, useHistory
} from 'react-router-dom';
import * as Yup from 'yup';
import { setIn } from 'final-form';
import {
  Form as FormHandler, Field
} from 'react-final-form';

import { Routes } from '../../routes';
import BgImage from '../../assets/img/illustrations/signin.svg';
import {SignUpService} from '../../services/AuthenticationServices';
import {setCookie} from '../../utils';
import getStore from '../../stores';
import {RingLoader} from 'react-spinners';
import Cookies from "js-cookie";
import moment from "moment/moment";
import _ from "lodash";

const schema = Yup.object({
  name: Yup.string().required('Name Required'),
  email: Yup.string().email().required('Email is Required'),
  password1: Yup.string().required('Password is required').min(6, 'Password must have atleast 6 characters'),
  password2: Yup.string().oneOf([Yup.ref('password1'), null], 'Passwords must match')

}),
      validate = async (values) => {
        try {
          await schema.validate(values, { abortEarly: false });
        } catch (err) {
          const errors = err.inner.reduce((formError, innerError) => {
            return setIn(formError, innerError.path, innerError.message);
          }, {});
          return errors;
        }
      };
export default function SignUp() {
  let [loading, setLoading] = useState(false),
      [success, setSuccess] = useState(''),
      [errorMessage, setErrorMessage] = useState(''),

      history = useHistory();
  const submit = (body)=>{
    setLoading(true);
    setSuccess('');
    setErrorMessage();
    return SignUpService({
      ...body,
      password: body.password1
    })
      .then((resp)=>{
        if (resp.success) {
          setSuccess("Email Verification link has been sent to your email. Please verify to continue.")
        } else{
          setErrorMessage(resp.message);
        }
      })
      .catch((err)=>{
        if(err.request){
          err.request.json()
            .then((json)=>{
              setErrorMessage(json.message);
            })
            .catch(()=>{
              setErrorMessage('Unknown Error Occurred. Please try again later');
            });
        } else {
          setErrorMessage('Unknown Error Occurred. Please try again later');
        }
      })
      .finally(()=>setLoading(false));

  };

  return (
    <main>
      <Modal as={Modal.Dialog} centered show={success} size={'sm'}>
        <Modal.Body>
          {success}
        </Modal.Body>
      </Modal>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>

          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                {errorMessage && <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>}
                <FormHandler onSubmit={submit}
                  validate={validate}
                  render={({handleSubmit})=>(
                    <Form className="mt-4" onSubmit={handleSubmit}>
                      <Form.Group id="name" className="mb-4">
                        <Form.Label>Your Name</Form.Label>
                        <InputGroup className="has-validation">
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Field name='name' render={({input, meta})=>(<>
                            <Form.Control isInvalid={meta.touched && meta.error} autoFocus required type="text" placeholder="John Doe" {...input}/>
                            {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                          </>)}/>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Your Email</Form.Label>
                        <InputGroup className="has-validation">
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Field name='email' render={({input, meta})=>(<>
                            <Form.Control required isInvalid={meta.touched && meta.error} type="email" placeholder="example@company.com" {...input}/>
                            {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                          </>)}/>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Your Password</Form.Label>
                        <InputGroup className="has-validation">
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Field name='password1' render={({input, meta})=>(<>
                            <Form.Control required isInvalid={meta.touched && meta.error} type="password" placeholder="Password" {...input}/>
                            {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                          </>)}/>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group id="confirmPassword" className="mb-4">
                        <Form.Label>Confirm Password</Form.Label>
                        <InputGroup className="has-validation">
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Field name='password2' render={({input, meta})=>(<>
                            <Form.Control required isInvalid={meta.touched && meta.error} type="password" placeholder="Confirm Password" {...input}/>
                            {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                          </>)}/>
                        </InputGroup>
                      </Form.Group>
                      <FormCheck type="checkbox" className="d-flex mb-4">
                        <FormCheck.Input required id="terms" className="me-2" />
                        <FormCheck.Label htmlFor="terms">
                                     I agree to the <Card.Link>terms and conditions</Card.Link>
                        </FormCheck.Label>
                      </FormCheck>
                      <Button variant="primary" type="submit" disabled={loading} className="w-100 d-inline-flex justify-content-center align-items-center">
                        Sign up {loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div> }
                      </Button>
                    </Form>
                  )}
                />


                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account? &nbsp;
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      Login here
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}
