import React, {Suspense} from 'react';
import {
    Route, Switch, Redirect, useLocation
} from 'react-router-dom';
import { Routes } from '../routes';

// pages
// import Upgrade from "./Upgrade";
import DashboardOverview from './dashboard/DashboardOverview';
// import Transactions from "./Transactions";
import Settings from './Settings';
import Profile from './Profile';
import BootstrapTables from './tables/BootstrapTables';
import Signin from './examples/Signin';
import Signup from './examples/Signup';
import ForgotPassword from './examples/ForgotPassword';
// import ResetPassword from "./examples/ResetPassword";
// import Lock from "./examples/Lock";
import NotFoundPage from './examples/NotFound';
// import ServerError from "./examples/ServerError";
import ChatPage from '../chat';
// documentation pages
// import DocsOverview from "./documentation/DocsOverview";
// import DocsDownload from "./documentation/DocsDownload";
// import DocsQuickStart from "./documentation/DocsQuickStart";
// import DocsLicense from "./documentation/DocsLicense";
// import DocsFolderStructure from "./documentation/DocsFolderStructure";
// import DocsBuild from "./documentation/DocsBuild";
// import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Preloader from '../components/Preloader';

// import Accordion from "./components/Accordion";
// import Alerts from "./components/Alerts";
// import Badges from "./components/Badges";
// import Breadcrumbs from "./components/Breadcrumbs";
// import Buttons from "./components/Buttons";
// import Forms from "./components/Forms";
// import Modals from "./components/Modals";
// import Navs from "./components/Navs";
// import Navbars from "./components/Navbars";
// import Pagination from "./components/Pagination";
// import Popovers from "./components/Popovers";
// import Progress from "./components/Progress";
// import Tables from "./components/Tables";
// import Tabs from "./components/Tabs";
// import Tooltips from "./components/Tooltips";
// import Toasts from "./components/Toasts";
import EmailVerification from './examples/EmailVerification';
// import MFASetup from "./examples/MFASetup";
// import MFAVerification from "./examples/MFAVerification";
// import MFAValidation from "./examples/MFAValidation";
// import PsychometricsReport from './PsychometricReports';
// import FWSReports from './FWSReports';
import getStore from '../stores';
import ResetPassword from './examples/ResetPassword';
// import StripeTest from './StripeTest';
// import OrdersHistoryReport from './Orders';
import GroupInvitation from "./examples/GroupInvitation";
import {observer} from "mobx-react";
// import AvailableTests from "./AvailableTests";
// import ResourcesPage from "./ResourcesPage";

const RouteWithLoader = ({ component: Component,authRequired=false, ...rest }) => {
        const location = useLocation();
        const authFilter =  (child)=>{
            if(authRequired && !getStore('UserStore').isTokenValid){
                return ()=>(<Redirect to={{
                    pathname: Routes.Signin.path,
                    state: { referrer: location.pathname }
                }}/>)
            } else {
                return child;
            }
        };

  return (
    <Route {...rest} render={authFilter((props)=><Component {...props}/>)} />
  );
},

      RouteWithSidebar = observer(({ component: Component, authRequired=true, ...rest  }) => {
        const authFilter =  (child)=>{
          if(authRequired && !getStore('UserStore').isTokenValid){
            return ()=>(<Redirect to={{
                pathname: Routes.Signin.path,
                state: { referrer: rest.path }
            }}/>)
          } else {
            return child;
          }
        };
        return (
          <Route {...rest} render={authFilter(props => (
            <>

              <Sidebar />

              <main className="content">
                <Navbar />
                <Component {...props} />
                {/*<Footer toggleSettings={toggleSettings} showSettings={showSettings} />*/}
              </main>
            </>
          ))}
          />
        );
      });

export default () => (
    <Suspense fallback={<p>Loading</p>}>
  <Switch>
    {/*<RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />*/}
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.EmailVerification.path} component={EmailVerification} />
    {/*<RouteWithLoader exact path={Routes.MFAVerification.path} component={MFAVerification} />*/}
    {/*<RouteWithLoader exact path={Routes.MFAValidation.path} component={MFAValidation} />*/}
    {/*<RouteWithLoader exact path={Routes.MFASetup.path} component={MFASetup} />*/}
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    {/*<RouteWithLoader exact path={Routes.Lock.path} component={Lock} />*/}
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    {/*<RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />*/}
    <RouteWithLoader exact authRequired path={Routes.GroupInvitation.path} component={GroupInvitation}/>
    {/* pages */}
    {/*<RouteWithSidebar path={Routes.Orders.path} component={OrdersHistoryReport} />*/}
    {/*<RouteWithSidebar exact path={'/orders'} component={OrdersHistoryReport} />*/}
      {Object.keys(Routes).map(function (key){
          let value = Routes[key];
          if(!value.component){
              return null;
          }
          if (value.sidebar){
              return <RouteWithSidebar exact={value.exact} path={value.path} component={value.component} authRequired={value.authRequired}/>
          }
          else
              return <RouteWithLoader exact={value.exact} path={value.path} component={value.component} authRequired={value.authRequired}/>
      })}
    <Route exact path="/">
      <Redirect to={Routes.Signin.path}/>
    </Route>
    {/* components */}
    {/*<RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />*/}
    {/*<RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />*/}
    {/*<RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />*/}
    {/*<RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />*/}
    {/*<RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />*/}
    {/*<RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />*/}
    {/*<RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />*/}
    {/*<RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />*/}
    {/*<RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />*/}
    {/*<RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />*/}
    {/*<RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />*/}
    {/*<RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />*/}
    {/*<RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />*/}
    {/*<RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />*/}
    {/*<RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />*/}
    {/*<RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />*/}
    {/**/}
    {/* documentation */}
    {/*<RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />*/}
    {/*<RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />*/}
    {/*<RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />*/}
    {/*<RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />*/}
    {/*<RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />*/}
    {/*<RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />*/}
    {/*<RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />*/}

    <Redirect to={Routes.NotFound.path} />
  </Switch>
    </Suspense>
);
