import React, {
  useCallback, useEffect, useMemo, useRef
} from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import  {
  MainContainer, ChatContainer, MessageList, Message, MessageInput, Avatar, ConversationList, Conversation, ConversationHeader, Sidebar, TypingIndicator, MessageGroup
} from '@chatscope/chat-ui-kit-react';
import {
  ChatMessage, MessageContentType, MessageDirection, MessageStatus,
  Presence, useChat, User, UserStatus
} from '@chatscope/use-chat';
import _ from 'lodash';
import {autorun, observable} from "mobx";
import getStore from "../stores";
import {observer} from "mobx-react";

function  ChatPage (){
      let {users,activeConversation,loadingConversations, conversations, currentUserName, currentMessages,setActiveConversationId, loadingActiveConversation,currentUserAvatar, draft, saveDraft, sendMessage} = getStore('ChatStore');
let  messageListRef = useRef();
  // useEffect( () => {
      //   let user = getUser(getStore('UserStore').data.user_id);
      //   setCurrentUser(user);
      // }, [users, setCurrentUser, getUser]);


  // useEffect(()=>{
  //   service.getHistory(activeConversation);
  // }, [activeConversation]);

  const getUser = (userId)=>{
    return _.find(users,{id:userId});
  }
  useEffect(()=>{
    //   service.getHistory(activeConversation);
    // }, [activeConversation]);
    return autorun( ()=>{
      let x = getStore('ChatStore').loadingActiveConversation;
      if (!x) {
        messageListRef.current.scrollToBottom();
      }
    });
  }, []);
  return <div style={{
    height: '600px',
    position: 'relative'
  }}>

    <MainContainer responsive>
      <Sidebar position="left" loading={loadingConversations}>
        {/*<Search placeholder="Search..." />*/}
        <ConversationList>
          {conversations.map(c => {
            // Helper for getting the data of the first participant
            const [avatar, name] = (() => {

              const participant = c.participants.length > 0 ? c.participants[0] : undefined;

              if (participant) {
                const user = getUser(participant.id);
                if (user) {

                  return [<Avatar src={user.avatar} />, user.username];

                }
              }

              return [undefined, undefined];
            })();

            return <Conversation key={c.id}
                                 name={name}
                                 info={c.draft ? `Draft: ${c.draft.replace(/<br>/g, '\n').replace(/&nbsp;/g, ' ')}` : ''}
                                 active={activeConversation?.id === c.id}
                                 unreadCnt={c.unreadCounter}
                                 onClick={() => setActiveConversationId(c.id)}>
              {avatar}
            </Conversation>;
          })}

        </ConversationList>
      </Sidebar>
      <ChatContainer >
        {activeConversation && <ConversationHeader>
          {<Avatar src={currentUserAvatar}/>}
          <ConversationHeader.Content userName={currentUserName} />
        </ConversationHeader>}
        <MessageList scrollBehavior="smooth"  loading={loadingActiveConversation} ref={messageListRef}>
          {activeConversation && _.uniq(currentMessages, 'id').map( (g) => <MessageGroup key={g.id} direction={g.direction}>
            <MessageGroup.Messages>
              {g.messages.map((m) => <Message key={m.id} model={{
                type: 'html',
                payload: m.content
              }} />)}
            </MessageGroup.Messages>
          </MessageGroup>)}
        </MessageList>
        <MessageInput placeholder="Type message here"
                      value={draft} onChange={saveDraft} onSend={sendMessage}
                      disabled={!activeConversation} attachButton={false} />
      </ChatContainer>
    </MainContainer>
  </div>;
}

export default observer(ChatPage);
