import HTTPService from './HTTPService';

export async function InvitationDetails({group_id}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/invitation/${group_id}`,
    method: 'GET'
  });
}

export async function AcceptGroupInvite({group_id}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/invitation/${group_id}/accept`,
    method: 'PUT',
  });
}

export async function LeaveGroup({}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/leave-group`,
    method: 'DELETE'
  });
}

export async function GroupDetails({}){
  return await HTTPService({ path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/group/get-user-group-detail`, });
}

export async function RejectGroupInvite({group_id}){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/invitation/${group_id}/reject`,
    method: 'PUT',
  });
}

export async function FWSTestsCount(){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/fws-tests/count`,
    method: 'GET'
  });
}

export async function PsychometricsTests({from, size}){
  const searchParams = new URLSearchParams({
    from,
    size
  });
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/profile-tests?${searchParams.toString()}`,
    method: 'GET'
  });
}

export async function PsychometricsTestsCount(){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/profile-tests/count`,
    method: 'GET'
  });
}

export async function AvailableResourcesService(){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/resources`,
    method: 'GET'
  });
}
