
import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft, faEnvelope, faUnlockAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  Col, Row, Form, Card, Button, Container, InputGroup
} from '@themesberg/react-bootstrap';
import OtpInput from 'react-otp-input';

import {
    Link, useHistory, useParams
} from 'react-router-dom';

import { Routes } from '../../routes';
import {
  EmailVerificationService, ResendEmailOtpService, SignUpService
} from '../../services/AuthenticationServices';
import {setCookie} from '../../utils';
import getStore from '../../stores';
import _ from 'lodash';
import {RingLoader} from 'react-spinners';
import Cookies from "js-cookie";


export default () => {
  let [otp, setOtp] = useState(''),
      [loading, setLoading] = useState(false),
      [emailLoading, setEmailLoading] = useState(false),
      [error, setError] = useState(false),
      history = useHistory(),
      emailId = getStore('UserStore').data.email;
    const submit = ()=>{
    if(otp.length === 6) {
      setLoading(true);
      return EmailVerificationService({otp: otp, emailId: emailId})
        .then((resp) => {
          if(resp.success) {
          localStorage.setItem('test_taker_token', resp.jwt);
            getStore('UserStore').initialise();
            history.push(Routes.DashboardOverview.path);
          } else{
            setError(true);
          }
        })
        .finally(()=>setLoading(false));
    }
  },

        resendEmail = ()=>{
          setEmailLoading(true);
          return ResendEmailOtpService()
            .then((resp)=>{
              if(_.get(resp, 'message') === 'User email is already verified') {
                setCookie('token', resp.jwt, 1);
                getStore('UserStore').initialise();
                history.push(Routes.DashboardOverview.path);
              }
            })
            .finally(()=>setEmailLoading(false));
        };


  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Verify Email</h3>
                <h6 className="mb-4">We have sent a 6 digit code to you email. Please enter the code to verify your email</h6>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  isInputNum
                  containerStyle="justify-content-around mb-5"
                  hasErrored={error}
                  errorStyle={{border: 'thin solid red'}}
                  inputStyle={{
                    width: '3rem',
                    height: '3rem',
                    fontSize: '2rem'
                  }}
                />
                <div className="d-flex justify-content-center">
                  <Button variant="primary" type="submit" disabled={loading} className="me-1 d-inline-flex justify-content-center align-items-center" onClick={submit}>Verify OTP{loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div> }</Button>
                  <Button variant="secondary" type="submit" disabled={emailLoading} className="ms-1 d-inline-flex justify-content-center align-items-center" onClick={resendEmail}>Resend OTP{emailLoading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={emailLoading} color="white" size={17} /></div> }</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
