import React, {useEffect, useRef, useState} from "react";
import {Col, Row, Image, Button, Container} from '@themesberg/react-bootstrap';
import {useHistory, useParams} from 'react-router-dom';
import {Routes} from "../../routes";
import {AcceptGroupInvite, InvitationDetails, RejectGroupInvite} from "../../services/GroupService";
import _ from "lodash";


export default () => {
    const containerRef = useRef(),
        history = useHistory(),
        [invitationData, setInvitationData] = useState({}),
        [submitLoading, setSubmitLoading] = useState(false),
        {group_id} = useParams(),
        [containerHeight, setContainerHeight] = useState(0);
    useEffect(() => {

        let observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.contentBoxSize) {
                    setContainerHeight(entry.contentRect.height);
                }
            }
        });
        observer.observe(containerRef.current);
        setContainerHeight(containerRef.current.offsetHeight);
        return () => observer.disconnect();
    }, []);

    useEffect(()=>{
        InvitationDetails({group_id})
          .then((data)=>{
            if(data.success){
              setInvitationData(data.invitation)
            }
          })
    },[group_id])

    const submit = (action)=>{
        setSubmitLoading(true);
        let promise;
        if (action === 'reject'){
            promise = RejectGroupInvite({group_id})
        }
        else {
            promise = AcceptGroupInvite({group_id})
        }
        promise.finally(()=>{
            history.push(Routes.DashboardOverview.path);
        })
    }
    return (
        <main>
            <section className="vh-100 d-flex mt-5 justify-content-center overflow-auto">
                <Container>
                    <Row>
                        <Col xs={12} className="text-center d-flex justify-content-center">
                            <div>
                                <div ref={containerRef}>
                                    <Image style={{
                                        minHeight: '300px',
                                        width: `${containerHeight}px`,
                                        objectFit: 'contain'
                                    }} roundedCircle src={_.get(invitationData,'group.image')} fluid/>
                                </div>
                                <h2 className="text-primary mt-5">
                                    {invitationData.sponsor} has invited you to join {_.get(invitationData,'group.name')}
                                </h2>
                                <Button disabled={submitLoading} variant="primary" className="animate-hover px-4" onClick={()=>submit('accept')}>
                                    Accept
                                </Button>
                                <Button disabled={submitLoading} variant="secondary" className="animate-hover ms-2 px-4" onClick={()=>submit('reject')}>
                                    Reject
                                </Button>
                                <p className="lead my-4">
                                    {_.get(invitationData,'group.description')}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
