import HTTPService from './HTTPService';

export async function SignUpService({email, name, password}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/signup`,
    method: 'POST',
    json: {
      email,
      name,
      password
    }
  });
}

export async function ChangePasswordService({oldPassword, newPassword}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/change-password`,
    method: 'POST',
    json: {
      currentPassword: oldPassword,
      password: newPassword
    }
  });
}

export async function UpdateProfileService({age, income, name}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/test-taker/profile`,
    method: 'PUT',
    json: {
      age,
      income,
      name
    }
  });
}

export async function GetProfileService(){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/test-taker/profile`,
    method: 'GET'
  });
}

export async function EmailVerificationService({otp, emailId}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/verify-email`,
    method: 'POST',
    json: {
      otp,
      emailId
    }
  });
}

export async function ResendEmailVerificationService({emailId}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/resend-verification-email`,
    method: 'POST',
    json: {
      emailId
    }
  });
}

export async function ResendEmailOtpService(){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/resend-verification-email`,
    method: 'POST'
  });
}

// export async function GenerateTotpUrlService(){
//   return HTTPService('http://localhost:3001/dev/affiliate/generateGoogleAuthLink','GET')
// }
//
//
// export async function TOTPValidationService({verificationCode}){
//   return HTTPService('http://localhost:3001/dev/affiliate/verifyOtpSetup','POST',{verificationCode})
// }

export async function SignInService({email, password}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/login`,
    method: 'POST',
    json: {
      email,
      password
    },
    options:{
      credentials:'include'
    }
  });
}

export async function SignOutService(){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/logout`,
    method: 'POST',
    options:{
      credentials:'include'
    }
  });
}


// export async function TOTPVerificationService({verificationCode}){
//   return HTTPService('http://localhost:3001/dev/affiliate/verifyOtp','POST',{verificationCode})
// }

export async function ForgotPasswordService({email}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/forget-password`,
    method: 'POST',
    json: {email}
  });
}

export async function ResetPasswordTokenVerificationService({token, userId}){
  const searchParams = new URLSearchParams({
    token,
    userId
  });
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/forget-password/token-verification?${searchParams.toString()}`,
    method: 'GET'
  });
}

export async function ResetPasswordChangeService({token, password, userId}){
  return HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/test-taker/forget-password/change`,
    method: 'POST',
    json: {
      token,
      password,
      userId
    }
  });
}
