
import React, {
  useEffect, useRef, useState
} from 'react';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt, faEnvelope, faSignOutAlt, faUnlockAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  Col, Row, Image, Card, Form, Button, InputGroup, FormCheck, FormControl
} from '@themesberg/react-bootstrap';
import getStore from '../stores';
import {
  Field, Form as FormHandler
} from 'react-final-form';
import * as Yup from 'yup';
import {setIn} from 'final-form';
import {
  ChangePasswordService, UpdateProfileService, GetProfileService
} from '../services/AuthenticationServices';
import {RingLoader} from 'react-spinners';
import {FWSTests} from '../services/ReportService';
import {GroupDetails, LeaveGroup} from "../services/GroupService";
import {useParams} from "react-router-dom";


export const GeneralInfoForm = () => {

  const ageOptions = [
    '<15',
    '16-18',
    '19-24',
    '25-29',
    '30-39',
    '40-49',
    '50-59',
    '60-69',
    '>70'
  ],

        incomeOptions = [
          '<1,999',
          '2,000-3,000',
          '3,000-4,000',
          '4,000-5,000',
          '5,000-10,000',
          '10,000-20,000',
          '>20,000'
        ];
  let [profileDetails, setProfileDetails] = useState({}),
      [loading, setLoading] = useState(false),
      [error, setError] = useState(''),
      [success, setSuccess] = useState(false);

  useEffect(()=>{
    setLoading(true);
    GetProfileService()
      .then((body)=>{
        setProfileDetails(body.data);
      }).finally(()=>setLoading(false));
  }, []);

  const schema = Yup.object({
    name: Yup.string().required('Full name is Required'),
    age: Yup.string().oneOf(ageOptions, 'Age group is required'),
    income: Yup.string().oneOf(incomeOptions, 'Income group is required')
  }),
        validate = async (values) => {
          try {
            await schema.validate(values, { abortEarly: false });
          } catch (err) {
            const errors = err.inner.reduce((formError, innerError) => {
              return setIn(formError, innerError.path, innerError.message);
            }, {});
            console.log(errors);
            return errors;
          }
        },

        submit = (body)=>{
          setLoading(true);
          return UpdateProfileService({
            age: body.age,
            income: body.income,
            name: body.name
          })
            .then((resp)=>{
              if(!resp.success){
                setError(resp.message);
              } else {
                setSuccess(true);
                setTimeout(()=>setSuccess(false), 2000);
              }
            })
            .finally(()=>setLoading(false));
        };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">General information</h5>
        {error &&
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        }
        {success &&
          <div className="alert alert-success" role="alert">
              Profile Updated Successfully
          </div>
        }
        <FormHandler onSubmit={submit}
          validate={validate}
          initialValues={profileDetails}
          render={({handleSubmit})=>(
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="name">
                    <Form.Label>Full Name</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name='name' render={({input, meta})=>(<>
                        <Form.Control required isInvalid={meta.touched && meta.error} type="text" placeholder="Enter your full name" {...input}/>
                        {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                      </>)}/>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control disabled type="email" value={getStore('UserStore').data.email} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6} className="mb-3">
                  <Form.Group id="age">
                    <Form.Label>Age Group</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name="age" options={ageOptions} defaultValue={-1} component="select">
                        { ({input,  meta, options}) => {
                          return (
                            <>
                              <Form.Control onChange={input.onChange} required isInvalid={meta.touched && meta.error} as="select" placeholder="Select your age group" {...input} >
                                <option value={-1} disabled>Select your age group</option>
                                {options.map((option)=>(
                                  <option key={option} value={option}>{option}</option>
                                ))}

                              </Form.Control>
                              {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                            </>
                          );
                        }}
                      </Field>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="income">
                    <Form.Label>Income Group</Form.Label>
                    <InputGroup className="has-validation">
                      <Field name="income" options={incomeOptions} defaultValue={-1} component="select">
                        { ({input,  meta, options}) => {
                          return (<>
                            <Form.Control onChange={input.onChange} required isInvalid={meta.touched && meta.error} as="select" placeholder="Select your income group" {...input} >
                              <option value={-1} disabled>Select your age group</option>
                              {options.map((option)=>(
                                <option key={option} value={option}>{option}</option>
                              ))}
                            </Form.Control>
                            {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                          </>
                          );
                        }}
                      </Field>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <div className="mt-3">
                <Button variant="primary" disabled={loading} type="submit" className="w-100  d-inline-flex justify-content-center align-items-center">
                  Update profile{loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div> }
                </Button>
              </div>
            </Form>
          )}
        />
      </Card.Body>
    </Card>
  );
};

export const ChangePasswordForm = () => {
  let [error, setError] = useState(''),
      [loading, setLoading] = useState(false),
      [success, setSuccess] = useState(false);
  const schema = Yup.object({
    oldPassword: Yup.string().required('Old Password is Required'),
    password1: Yup.string().required('New Password is required').min(6, 'Password should be at least 6 characters'),
    password2: Yup.string().oneOf([Yup.ref('password1'), null], 'New Password must match')

  }),
        validate = async (values) => {
          try {
            await schema.validate(values, { abortEarly: false });
          } catch (err) {
            const errors = err.inner.reduce((formError, innerError) => {
              return setIn(formError, innerError.path, innerError.message);
            }, {});
            return errors;
          }
        },

        submit = (body)=>{
          setLoading(true);
          return ChangePasswordService({
            oldPassword: body.oldPassword,
            newPassword: body.password1
          })
            .then((resp)=>{
              if(resp.error){
                setError(resp.message);
              } else {
                setSuccess(true);
                setTimeout(()=>setSuccess(false), 2000);
              }
            })
            .finally(()=>setLoading(false));
        };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Change Password</h5>
        {error &&
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
        }
        {success &&
            <div className="alert alert-success" role="alert">
                Profile Updated Successfully
            </div>
        }
        <FormHandler onSubmit={submit}
          validate={validate}
          render={({handleSubmit})=>(
            <Form className="mt-4" onSubmit={handleSubmit}>
              <Form.Group id="oldPassword" className="mb-4">
                <Form.Label>Old Password</Form.Label>
                <InputGroup className="has-validation">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faUnlockAlt} />
                  </InputGroup.Text>
                  <Field name='oldPassword' render={({input, meta})=>(<>
                    <Form.Control required isInvalid={meta.touched && meta.error} type="password" placeholder="Password" {...input}/>
                    {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                  </>
                  )}/>
                </InputGroup>
              </Form.Group>
              <Form.Group id="password" className="mb-4">
                <Form.Label>New Password</Form.Label>
                <InputGroup className="has-validation">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faUnlockAlt} />
                  </InputGroup.Text>
                  <Field name='password1' render={({input, meta})=>(<>
                    <Form.Control required isInvalid={meta.touched && meta.error} type="password" placeholder="Password" {...input}/>
                    {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                  </>)}/>
                </InputGroup>
              </Form.Group>
              <Form.Group id="confirmPassword" className="mb-4">
                <Form.Label>Re-Enter New Password</Form.Label>
                <InputGroup className="has-validation">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faUnlockAlt} />
                  </InputGroup.Text>
                  <Field name='password2' render={({input, meta})=>(<>
                    <Form.Control required isInvalid={meta.touched && meta.error} type="password" placeholder="Confirm Password" {...input}/>
                    {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                  </>
                  )}/>
                </InputGroup>
              </Form.Group>
              <Button variant="primary" disabled={loading} type="submit" className="w-100  d-inline-flex justify-content-center align-items-center">
                               Change Password{loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div> }
              </Button>
            </Form>
          )}
        />
      </Card.Body>
    </Card>
  );
};

export const GroupDetail = () => {
    let [groupDetails, setGroupDetails] = useState({}),
        [loading, setLoading] = useState(false),
        containerRef = useRef(),
        [leaveGroupLoading, setLeaveGroupLoading] = useState(false),
        [containerHeight, setContainerHeight] = useState(0);

    const search = () => {
        // TODO: Add error handling and not found handling
        setLoading(true);
        GroupDetails({})
            .then((data)=>{
                setGroupDetails(data.group);
            }).catch((err)=>{
                console.log(err);
            if(err.request.status === 404){
                setGroupDetails({})
            }
        })
            .finally(()=>setLoading(false));
    }
    useEffect(()=>{
       search();
    }, []);

    useEffect(()=>{
        if(_.isEmpty(groupDetails)) return;
        let observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                if(entry.contentBoxSize){
                    setContainerHeight(entry.contentRect.width);
                }
            }
        });
        observer.observe(containerRef.current);
        setContainerHeight(containerRef.current.offsetWidth);
        return ()=>observer.disconnect();
    }, [groupDetails]);

    if(!_.isEmpty(groupDetails)){
        return (
            <Card border="light" className="bg-white shadow-sm mb-4">
                <Card.Body>
                    <h5 className="mb-4">Group Detail</h5>
                    <>
                        <Row className="py-4">
                            <Col lg={2} ref={containerRef} >
                                <Image  style={{
                                    minWidth: '100px',
                                    height: `${containerHeight}px`,
                                    objectFit: 'contain'
                                }} fluid roundedCircle src={groupDetails.image} />
                            </Col>
                            <Col lg={10} className="mb-4 mb-xl-0">
                                <h4>{groupDetails.name}</h4>
                                <p>{groupDetails.description}</p>
                            </Col>
                        </Row>
                        <div>
                            <Button className='w-100 d-inline-flex justify-content-center align-items-center' onClick={()=>{
                                setLeaveGroupLoading(true);
                                LeaveGroup({})
                                    .then((data)=>{
                                        if(data.success){
                                            search();
                                            return;
                                        }
                                    })
                                    .finally(()=>setLeaveGroupLoading(false));
                            } }><FontAwesomeIcon icon={faSignOutAlt} size='xs' className='me-2' />Leave Group {leaveGroupLoading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={leaveGroupLoading} color="white" size={17} /></div> }</Button>
                        </div>
                    </>
                </Card.Body>
            </Card>
        );
    }
    else {
        return null;
    }

}



