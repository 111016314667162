
import React, {
  useEffect, useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope, faUnlockAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  Col, Row, Form, Card, Button, Container, InputGroup, FormControl
} from '@themesberg/react-bootstrap';
import {
  Link, useHistory
} from 'react-router-dom';

import { Routes } from '../../routes';
import BgImage from '../../assets/img/illustrations/signin.svg';
import {autorun, when} from 'mobx';
import getStore from '../../stores';
import * as Yup from 'yup';
import {setIn} from 'final-form';
import {
  Field, Form as FormHandler
} from 'react-final-form';
import {ResendEmailVerificationService, SignInService} from '../../services/AuthenticationServices';
import {setCookie} from '../../utils';
import {RingLoader} from 'react-spinners';
import Cookies from "js-cookie";
import _ from 'lodash';

const schema = Yup.object({
  email: Yup.string().email().required('Email is Required'),
  password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
}),
      validate = async (values) => {
        try {
          await schema.validate(values, { abortEarly: false });
        } catch (err) {
          const errors = err.inner.reduce((formError, innerError) => {
            return setIn(formError, innerError.path, innerError.message);
          }, {});
          return errors;
        }
      };

export default function SignIn() {
  let history = useHistory(),
      [loading, setLoading] = useState(false),

      [errorMessage, setErrorMessage] = useState('');

  useEffect(()=>{
    return autorun(()=>{
      let {isTokenValid} = getStore('UserStore')
      if(!isTokenValid){return;}
      if (isTokenValid) {
        history.push(_.get(history,'location.state.referrer') || Routes.DashboardOverview.path);
      }
    }
    );
  }, [history]);

  const submit = (body)=>{
    setErrorMessage('')
    setLoading(true);
    return SignInService(body)
      .then((resp)=>{
        if(!resp.error) {
          localStorage.setItem('test_taker_token', resp.jwt);
          getStore('UserStore').initialise();
          // history.push(Routes.DashboardOverview.path);
        } else {
          if (resp.code === 'NOT_VERIFIED'){
            setErrorMessage(resp.code)
          }
          setErrorMessage(resp.message);
        }
      })
      .catch((err)=>{
        if(err.request){
          err.request.json()
            .then((json)=>{
              if (json.code === 'NOT_VERIFIED'){
                setErrorMessage(json.code)
              }
              setErrorMessage(json.message);
            })
            .catch(()=>{
              setErrorMessage('Unknown Error Occurred. Please try again later');
            });
        } else {
          setErrorMessage('Unknown Error Occurred. Please try again later');
        }
      })
      .finally(()=>setLoading(false));
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                {errorMessage && <div className="alert alert-danger" role="alert">
                  {errorMessage === 'NOT_VERIFIED'?<span>Your email is not verified. Click <a onClick={()=>{
                    ResendEmailVerificationService({})
                        .then(r => {
                          if (r.error){
                            setErrorMessage(r.message)
                          }
                        } )
                  }
                  }>here</a> to resend the verification email</span>:errorMessage}
                </div>}
                <FormHandler onSubmit={submit}
                  validate={validate}
                  render={({handleSubmit, })=>(
                    <Form className="mt-4" onSubmit={handleSubmit}>
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Your Email</Form.Label>
                        <InputGroup className="has-validation">
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Field name='email' render={({input, meta})=>(<>
                            <Form.Control required isInvalid={meta.touched && meta.error} type="email" placeholder="example@company.com" {...input}/>
                            {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                          </>
                          )}/>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group>
                        <Form.Group id="password" className="mb-4">
                          <Form.Label>Your Password</Form.Label>
                          <InputGroup className="has-validation">
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Field name='password' type="password" render={({input, meta})=>(<>
                              <Form.Control required isInvalid={meta.touched && meta.error} type="password" placeholder="Password" {...input}/>
                              {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                            </>)}/>

                          </InputGroup>
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Card.Link className="small text-end" as={Link} to={Routes.ForgotPassword.path}>Lost password?</Card.Link>
                        </div>
                      </Form.Group>
                      <Button variant="primary" type="submit" disabled={loading} className="w-100 d-inline-flex justify-content-center align-items-center" >
                                     Sign in{loading  && <div className="ms-2 d-inline-flex align-items-center"><RingLoader loading={loading} color="white" size={17} /></div> }
                      </Button>
                      <Form.Group controlId="formBasicCheckbox" className="mt-2">
                        <Form.Check type="checkbox" required label={<>I have read, accepted and will comply with the <a target="_blank" rel="noopener noreferrer" href="https://moneylife-staging-docker.s3-ap-southeast-1.amazonaws.com/media/Conduct+n+Ethics+(v04-2020).pdf"><b>Code of Conduct & Code of Ethical Use</b></a> </>} />
                      </Form.Group>
                    </Form>
                  )}/>


                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {' Create account '}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}
