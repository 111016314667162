
import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import {
  useHistory, useLocation
} from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie,
  faSignOutAlt,
  faTable,
  faTimes,
  faUserCircle,
  faCog, faLink, faComments,
} from '@fortawesome/free-solid-svg-icons';
import {
  Nav, Badge, Image, Button, Accordion, Navbar
} from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from '../routes';
// import ThemesbergLogo from '../assets/img/themesberg.svg';
import ReactHero from '../assets/img/technologies/react-hero-logo.svg';
import ProfilePicture from '../assets/img/team/profile-picture-3.jpg';
import Avatar from 'react-avatar';
import getStore from '../stores';

export default (props = {}) => {
  const location = useLocation(),
        { pathname } = location,
        [show, setShow] = useState(false),
        showClass = show ? 'show' : '',
        history = useHistory(),

        onCollapse = () => setShow(!show),

        CollapsableNavItem = (props) => {
          const { eventKey, title, icon, children = null } = props,
                defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

          return (
            <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
              <Accordion.Item eventKey={eventKey}>
                <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
                    <span className="sidebar-text">{title}</span>
                  </span>
                </Accordion.Button>
                <Accordion.Body className="multi-level">
                  <Nav className="flex-column">
                    {children}
                  </Nav>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        },

        NavItem = (props) => {
          const { title, link, external, target, icon, image, badgeText, badgeBg = 'secondary', badgeColor = 'primary' } = props,
                classNames = badgeText ? 'd-flex justify-content-start align-items-center justify-content-between' : '',
                navItemClassName = link === pathname ? 'active' : '',
                linkProps = external ? { href: link } : {
                  as: Link,
                  to: link
                };

          return (
            <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
              <Nav.Link {...linkProps} target={target} className={classNames}>
                <span>
                  {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
                  {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

                  <span className="sidebar-text">{title}</span>
                </span>
                {badgeText ? (
                  <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
                ) : null}
              </Nav.Link>
            </Nav.Item>
          );
        };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src="/logo-primary.png" className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Avatar name={getStore('UserStore').data.name} round={true} size={40}/>
                </div>
                <div className="d-block">
                  <h6>Hi, {getStore('UserStore').data.name}</h6>
                  <Button variant="secondary" size="xs" onClick={()=>{
                    getStore('UserStore').logout();
                    history.push(Routes.Signin.path);
                  }} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {/*<NavItem title="Volt React" link={Routes.Presentation.path} image={ReactHero} />*/}

              <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />
              <NavItem title="Available Tests" link={Routes.AvailableTests.path} icon={faLink} />
              <NavItem title="Resources" link={Routes.AvailableResources.path} icon={faLink} />
              <CollapsableNavItem eventKey="reports/" title="Reports" icon={faTable}>
                <NavItem title="Profile Reports" link={Routes.PsychometricsReports.path} />
                <NavItem title="FWS Reports" link={Routes.FWSReports.path} />
              </CollapsableNavItem>
              {/*<NavItem title="Chat" link={Routes.Chat.path} icon={faComments} />*/}
              {/*<NavItem title="Buy Tests" icon={faDollarSign} link={Routes.BuyTests.path} />*/}
              {/*<NavItem title="My Orders" icon={faCartArrowDown} link={'/orders/'} />*/}
              <NavItem title="My Profile" icon={faUserCircle} link={Routes.Profile.path} />
              <NavItem title="Settings" icon={faCog} link={Routes.Settings.path} />


            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
