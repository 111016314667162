import HTTPService from "./HTTPService";

export async function CreateChatService({sponsor_id}){
    return await HTTPService({
        path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/chat/start/${sponsor_id}`,
        method: 'POST'
    });
}

export async function ConversationsList(){
    return await HTTPService({
        path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/chat/conversations`,
        method: 'GET'
    })
}
export async function ConversationsHistory({conversationId}){
    return await HTTPService({
        path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/chat/conversations/${conversationId}/history`,
        method: 'GET'
    })
}
