import HTTPService from './HTTPService';

export async function FWSTests({from, size}){
  const searchParams = new URLSearchParams({
    from,
    size
  });
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/fws-tests?${searchParams.toString()}`,
    method: 'GET'
  });
}

export async function FWSTestsCount(){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/fws-tests/count`,
    method: 'GET'
  });
}

export async function PsychometricsTests({from, size}){
  const searchParams = new URLSearchParams({
    from,
    size
  });
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/profile-tests?${searchParams.toString()}`,
    method: 'GET'
  });
}

export async function PsychometricsTestsCount(){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/profile-tests/count`,
    method: 'GET'
  });
}

export async function LatestTests({count=10}){
  const searchParams = new URLSearchParams({count});
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/latest-reports?${searchParams.toString()}`});
}

export async function AvailableTestsService(){
  return await HTTPService({
    path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/user/available-tests`,
    method: 'GET'
  });
}


export async function GetReportViewUrl({report_type, report_id}){
  return await HTTPService({path: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/report/${report_type}/${report_id}/token`});
}
