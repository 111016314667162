
import React from 'react';
import { faCashRegister, faChartLine} from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from '@themesberg/react-bootstrap';

import {
  CounterWidget,
  CircleChartWidget,
  BarChartWidget,
  TeamMembersWidget,
  ProgressTrackWidget,
  RankingWidget,
  SalesValueWidget,
  SalesValueWidgetPhone,
  AcquisitionWidget,
  StripeConnectWidget, PendingTestsWidget
} from '../../components/Widgets';
import { RecentTestsTable } from '../../components/Tables';
import { trafficShares, totalOrders } from '../../data/charts';
import getStore from '../../stores';
import {FWSRecentWidget} from '../../components/Widgets';
import {PsychometricRecentWidget} from '../../components/Widgets';

export default () => {
  return (
    <>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} className="mb-4">
          <PsychometricRecentWidget/>
        </Col>

        <Col xs={12} sm={6} className="mb-4">
          <FWSRecentWidget />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-4">
          <Row>
            <Col xs={12} className="mb-4">
              <RecentTestsTable />
            </Col>
          </Row>
        </Col>

        {/*<Col xs={12} xl={4}>*/}
        {/*  <Row>*/}
        {/*    <Col xs={12} className="mb-4">*/}
        {/*      <PendingTestsWidget/>*/}
        {/*    </Col>*/}

        {/*  </Row>*/}
        {/*</Col>*/}
      </Row>
    </>
  );
};
