import UserStore from "./UserStore";
import ChatStore from "./ChatStore";

const stores = {
  UserStore,
  // ChatStore
}
export default function getStore(storeName){
  return stores[storeName];
}
