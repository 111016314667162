import {lazy} from "react";
import DashboardOverview from "./pages/dashboard/DashboardOverview";

export const Routes = {
  // pages
  // Presentation: { path: "/" },
  DashboardOverview: {
    path: '/dashboard/overview',
    component: lazy(()=>import('../src/pages/dashboard/DashboardOverview')),
    sidebar:true,
    exact:true,
    authRequired: true
  },
  AvailableTests: {
    path: '/available-tests',
    component: lazy(()=>import('./pages/AvailableTests')),
    sidebar:true,
    exact:true,
    authRequired: true
  },
  AvailableResources: {
    path:'/resources',
    component: lazy(()=>import('./pages/ResourcesPage')),
    sidebar:true,
    exact:true,
    authRequired: true
  },
  Settings: {
    path: '/settings',
    component: lazy(()=>import('./pages/Settings')),
    sidebar:true,
    exact:true,
    authRequired: true
  },
  Profile: {
    path: '/profile',
    component: lazy(()=>import('./pages/Profile')),
    sidebar:true,
    exact:true,
    authRequired: true
  },
  // Chat: {
  //   path: '/chat',
  //   component: lazy(()=>import('./chat/index')),
  //   sidebar:true,
  //   exact:true,
  //   authRequired: true
  // },
  PsychometricsReports: {
    path: '/reports/psychometrics',
    component: lazy(()=>import('./pages/PsychometricReports')),
    sidebar:true,
    exact:true,
    authRequired: true
  },
  FWSReports: {
    path: '/reports/fws',
    component: lazy(()=>import('./pages/FWSReports')),
    sidebar:true,
    exact:true,
    authRequired: true
  },
  Transactions: { path: '/transactions' },
  Upgrade: { path: '/upgrade' },
  Orders: {path: '/orders/:orderId'},
  BuyTests: {path: '/buy-tests'},
  // BootstrapTables: { path: '/tables/bootstrap-tables' },
  // Billing: { path: '/examples/billing' },
  // Invoice: { path: '/examples/invoice' },
  Signin: { path: '/sign-in' },
  Signup: { path: '/sign-up' },
  EmailVerification: { path: '/email-verification' },
  // MFAValidation: { path: '/mfa-validation' },
  // MFAVerification: { path: '/mfa-verification' },
  // MFASetup: { path: '/mfa-setup' },
  ForgotPassword: { path: '/forgot-password' },
  ResetPassword: { path: '/password-reset/:userId/:resetToken' },
  Lock: { path: '/examples/lock' },
  NotFound: { path: '/examples/404' },
  ServerError: { path: '/examples/500' },
  GroupInvitation: {path:'/group-invitation/:group_id'},

  // docs
  // DocsOverview: { path: '/documentation/overview' },
  // DocsDownload: { path: '/documentation/download' },
  // DocsQuickStart: { path: '/documentation/quick-start' },
  // DocsLicense: { path: '/documentation/license' },
  // DocsFolderStructure: { path: '/documentation/folder-structure' },
  // DocsBuild: { path: '/documentation/build-tools' },
  // DocsChangelog: { path: '/documentation/changelog' },

  // components
  // Accordions: { path: '/components/accordions' },
  // Alerts: { path: '/components/alerts' },
  // Badges: { path: '/components/badges' },
  // Widgets: { path: '/widgets' },
  // Breadcrumbs: { path: '/components/breadcrumbs' },
  // Buttons: { path: '/components/buttons' },
  // Forms: { path: '/components/forms' },
  // Modals: { path: '/components/modals' },
  // Navs: { path: '/components/navs' },
  // Navbars: { path: '/components/navbars' },
  // Pagination: { path: '/components/pagination' },
  // Popovers: { path: '/components/popovers' },
  // Progress: { path: '/components/progress' },
  // Tables: { path: '/components/tables' },
  // Tabs: { path: '/components/tabs' },
  // Tooltips: { path: '/components/tooltips' },
  // Toasts: { path: '/components/toasts' },
  // WidgetsComponent: { path: '/components/widgets' }
};
