import getStore from '../stores';
import {Routes} from '../routes';

async function HTTPRequest({path, method = 'GET', json = undefined, body = undefined, options = {}}){

  let req_headers  = {};
  if (getStore('UserStore').jwt) {
    if (getStore('UserStore').isTokenValid) {
      req_headers['authorization'] = 'Bearer ' + getStore('UserStore').jwt;
    } else {
      getStore('UserStore').logout();
      window.history.push(Routes.Signin.path);
    }
  }


  if(json){
    body=JSON.stringify(json);
    req_headers['Content-Type'] = 'application/json';
  }

  const params = {
    method,
    body: body,
    headers: req_headers,
    mode: 'cors',
    ...options
  },
        request = await fetch(path, params);
  if(request.status >= 400){
    let err = Error(`Request failed with status: ${request.status}`);
    err.request = request;
    throw err;
  }
  return request.json();
}

export default HTTPRequest;
