import React from 'react';
import {Col, Row} from "@themesberg/react-bootstrap";

import {ChangePasswordForm, GroupDetail} from '../components/Forms';
import {GroupDetails} from "../services/GroupService";
export default () => {
  return (
    <>
      <Row>
        <Col lg={6} md={12}>
          <ChangePasswordForm />
        </Col>
          <Col lg={6} md={12}>
              <GroupDetail />
          </Col>
      </Row>
    </>
  );
};
