
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEnvelope, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import {Col, Row, Form, Card, Button, Container, InputGroup, FormControl} from '@themesberg/react-bootstrap';
import {Link, useHistory, useParams} from 'react-router-dom';

import { Routes } from '../../routes';
import {ResetPasswordChangeService, ResetPasswordTokenVerificationService} from '../../services/AuthenticationServices';
import {Field, Form as FormHandler} from 'react-final-form';
import * as Yup from "yup";
import {setIn} from "final-form";
import {RingLoader} from "react-spinners";

const schema = Yup.object({
    password: Yup.string().required('Password is required').min(6, 'Password must have at least 6 characters'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')

  }),
  validate = async (values) => {
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err) {
      const errors = err.inner.reduce((formError, innerError) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      console.log(errors)
      return errors;
    }
  };

export default () => {
  let [error, setError] = useState(''),
      [email, setEmail] = useState(''),
      [loading, setLoading] = useState(false),
      [success, setSuccess] = useState(false),
      history = useHistory(),
      { userId, resetToken } = useParams();
  useEffect(()=>{
    ResetPasswordTokenVerificationService({token: resetToken,
        userId})
      .then((resp)=>{
          if(!resp.success){
              setError(resp.message);
          }
          else {
              setEmail(resp.email);
          }
      })
      .catch(()=>setError("Link cannot be verified. Please try again."))

    ;
  }, []);

  const submit = ({password})=>{
    setLoading(true);
    setError('');
    ResetPasswordChangeService({token: resetToken, password, userId})
      .then((resp)=>{
          if(resp.error){
              setError(resp.message);
          }
          else {
              setSuccess(true);
              setTimeout(()=>{
                  history.push(Routes.Signin.path);
              });
          }
      })
      .catch(()=>setError("Error occurred. Please try again."))
      .finally(()=>setLoading(false))
  }
  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset password</h3>
                {error && <div className="alert alert-danger" role="alert">
                  {error}
                </div>}
                {success && <div className="alert alert-success" role="alert">
                  Your password has been changed successfully.
                </div>}
                <FormHandler onSubmit={submit}
                  validate={validate}
                  render={({handleSubmit})=>(
                    <Form onSubmit={handleSubmit}>
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Your Email</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Form.Control disabled required type="email" value={email} placeholder="example@company.com" />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>New Password</Form.Label>
                        <InputGroup className="has-validation">
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Field name='password' type="password" render={({input, meta})=>(<>
                            <Form.Control required isInvalid={meta.touched && meta.error} type="password" placeholder="Password" {...input}/>
                            {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                          </>)}/>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group id="confirmPassword" className="mb-4">
                        <Form.Label>Confirm Password</Form.Label>
                        <InputGroup className="has-validation">
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Field type="password" name='confirmPassword' render={({input, meta})=>(<>
                          <Form.Control required isInvalid={meta.touched && meta.error} type="password" placeholder="Confirm Password" {...input}/>
                            {meta.touched && meta.error && <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>}
                          </>)}/>
                        </InputGroup>
                      </Form.Group>
                      <Button variant="primary" disabled={!email || loading} type="submit" className="w-100 d-inline-flex justify-content-center align-items-center">
                    Reset password{loading  && <div className={'ms-2 d-inline-flex align-items-center'}><RingLoader loading={loading} color={'white'} size={17} /></div> }
                      </Button>
                    </Form>
                  )}/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
